<template>
  <div>
    <a-form layout="inline">
      <a-form-item
          label="限制类别"
      >
        <a-select
            size="default"
            placeholder="选择限制类别"
            style="width: 250px"
            @change="handleChange"
            allowClear
          >
            <a-select-option v-for="i in typeList" :key="i.typeConstant">
              {{ i.typeName}}
            </a-select-option>
          </a-select>
      </a-form-item>
      <a-form-item  label="限制子类别" >
        <a-select
            size="default"
            placeholder="选择限制子类别"
            style="width: 250px"
            @change="handleChangeChild"
            allowClear
          >
            <a-select-option v-for="i in childTypeList" :key="i.constant">
              {{ i.name}}
            </a-select-option>
          </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary"  style="margin-left: 24px;" @click="onSearch"> 查询 </a-button>
        <a-button type="dashed" style="margin-left: 24px;" @click="showAddModal"> 新增 </a-button>
           <a-popconfirm
          title="确定删除?"
          ok-text="是"
          cancel-text="否"
          @confirm="deleteByIds"
        >
          <a-button type="danger" style="margin-left: 24px;" > 批量删除 </a-button>
        </a-popconfirm>
      </a-form-item>
    </a-form>

    <a-table bordered :columns="columns" :data-source="data" :loading="loading" :pagination="pagination" style="margin-top: 24px;" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" >
      <span slot="restrictType" slot-scope="text, record">{{record.restrictType }}</span>
      <span slot="action" slot-scope="record">
        <a  @click="showEdit(record)" style="margin-right: 10px;">编辑</a>
        <a-popconfirm
          title="确定删除?"
          ok-text="是"
          cancel-text="否"
          @confirm="confirm(record)"
        >
          <a>删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <a-modal v-model="addVisible" title="新增" @ok="handleOk" width="900px">
      <a-row :gutter="20" type="flex" justify="start"   style="width: 100%;flex-wrap:wrap;">
        <a-col :span="12">
          <p>限制类别：<br/><br/>
          <a-select
              size="default"
              placeholder="选择限制类别"
              style="width: 250px"
              @change="handleAddChange"
            >
              <a-select-option v-for="i in typeList" :key="i.typeConstant">
                {{ i.typeName}}
              </a-select-option>
            </a-select>
          </p>
        </a-col>
        <a-col :span="12">
          <p>限制子类别：<br/><br/>
          <a-select
              size="default"
              placeholder="选择限制子类别"
              style="width: 250px"
              @change="handleAddChangeChild"
            >
              <a-select-option v-for="i in childTypeList" :key="i.constant">
                {{ i.name}}
              </a-select-option>
            </a-select>
          </p>
        </a-col>
        <a-col :span="12">
          <p >限制名称：<br/><br/> <a-input v-model="ADD_PARAMS.restrictName" style="width: 250px;"/> </p>
        </a-col>
        <a-col :span="12">
          <p>限制描述：<br/><br/> <a-input v-model="ADD_PARAMS.restrictDescribe" style="width: 250px;"/> </p>
        </a-col>
        <a-col :span="12">
          <p>限制条件：<br/><br/>
            <a-input-group compact  >
            <a-select style="width: 60px;" @change="handleAddChangeCondition">
              <a-select-option :value="item" v-for="(item, index) in condition" :key="index">
                {{item}}
              </a-select-option>
            </a-select>
            <a-input style="width: 190px;" v-model="conditionNumber" />
          </a-input-group>
          </p>
        </a-col>
        <a-col :span="12">
          <p>异常提示信息：<br/><br/> <a-input v-model="ADD_PARAMS.exceptionName" style="width: 250px;"/> </p>
        </a-col>
        <a-col :span="12">
          <p>配置是否通用：<br/><br/>
            <a-radio-group v-model="ADD_PARAMS.common" @change="onChangeCommon">
              <a-radio :value="true">
                是
              </a-radio>
              <a-radio :value="false">
                否
              </a-radio>
            </a-radio-group>
          </p>
        </a-col>
        <a-col :span="12">
            <p>行政区类别：<br/><br/>
              <a-select style="width: 250px;" @change="handleAddChangeRegionType">
                <a-select-option :value="item" v-for="(item, index) in regionTypes" :key="index">
                  {{item}}
                </a-select-option>
              </a-select>
            </p>
        </a-col>
        <a-col :span="12">
          <p>行政区编号：<br/><br/>
            <a-input v-model="ADD_PARAMS.regionCode" style="width: 250px;"/>
          </p>
        </a-col>
      </a-row>
    </a-modal>
    <edit-restrict ref="edit" @query="queryRestrictList"></edit-restrict>
  </div>
</template>

<script>
import { queryRestrictTypeListApi, queryRestrictChildListApi, queryRestrictListApi, updateRestrictApi, deleteRestrictApi, queryExceptionRegionTypeApi} from '@/api/restrict'
import _ from "lodash";
import editRestrict from './component/editRestrict'
export default {
  name: "restrictList",
  components: {editRestrict},
  data() {
    return {
      value: '',
      ids: [],
      selectedRowKeys: [],
      conditionTag: '',
      conditionNumber: '',
      ADD_PARAMS: {
        restrictType: '',
        restrictName: '',
        restrictDescribe: '',
        constant: '',
        restrictCondition: '',
        exceptionName: '',
        common: '',
        regionType: '',
        regionCode: ''
      },
      condition: ['>=', '>', '=', '<=', '<', '!='],
      params: {
        page: 1,
        limit: 10
      },
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '限制名称',
          dataIndex: 'restrictName',
          key: 'restrictName',
        },
        {
          title: '限制描述',
          dataIndex: 'restrictDescribe',
          key: 'restrictDescribe',
        },
        {
          title: '限制类型',
          dataIndex: 'restrictType',
          key: 'restrictType',
          scopedSlots: {customRender: 'restrictType'},
        },
        {
          title: '限制子类',
          dataIndex: 'constant',
          key: 'constant',
        },
        {
          title: '限制条件',
          dataIndex: 'restrictCondition',
          key: 'restrictCondition',
        },
        {
          title: '异常提示',
          dataIndex: 'exceptionName',
          key: 'exceptionName',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {customRender: 'action'},
        }
      ],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        onChange: (current) => this.changePage(current)
      },
      loading: true,
      typeList: [],
      childTypeList: [],
      addVisible: false,
      editVisible: false,
      regionTypes: []
    }
  },
  mounted() {
    this.queryRestrictList()
    this.queryExceptionRegionType()
  },
  methods: {
    handleAddChangeRegionType(e) {
      this.ADD_PARAMS.regionType = e
    },
     onChangeCommon(e) {
      this.ADD_PARAMS.common = e.target.value
    },
    queryExceptionRegionType() {
      queryExceptionRegionTypeApi().then(res => {
        this.regionTypes = res.result
      })
    },
    confirm(record){
      this.ids = []
      this.ids.push(record.id)
      deleteRestrictApi(this.ids).then(res => {
        this.$message.success('删除成功')
        this.queryRestrictList()
        this.ids = []
      })
    },
    deleteByIds() {
      this.ids = []
      this.selectedRowKeys.forEach(item => {
        this.ids.push(this.data[item].id)
      })
      deleteRestrictApi(this.ids).then(res => {
        this.$message.success('删除成功')
        this.queryRestrictList()
        this.selectedRowKeys = []
        this.ids = []
      })
    },
    handleAddChange(value) {
      this.ADD_PARAMS.restrictType = value
      this.queryRestrictChildList(value)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleAddChangeChild(value) {
      this.ADD_PARAMS.constant = value
    },
    handleAddChangeCondition(value) {
      this.conditionTag = value
    },
    showAddModal() {
      this.addVisible = true
      this.queryExceptionRegionType()
    },
    showEdit(record) {
      this.$refs.edit.showEditModal(record)
    },
    handleOk() {
      this.ADD_PARAMS.restrictCondition = this.conditionTag + this.conditionNumber
      updateRestrictApi({...this.ADD_PARAMS}).then(res => {
        this.$message.success('新增成功')
        this.addVisible = false
        this.queryRestrictList()
      })
    },
    async queryRestrictList() {
      this.loading = true
      this.params.page = this.pagination.current
      await this.queryRestrictTypeList()
      await queryRestrictListApi({...this.params}).then(res => {
        this.loading = false
        this.data = res.result.content
        this.pagination.total = res.result.totalElements

      })
    },
    async queryRestrictTypeList() {
      let data = await queryRestrictTypeListApi()
      this.typeList = data.result
      console.log(this.typeList)
    },
    queryRestrictChildList(data) {
      queryRestrictChildListApi({typeConstant: data}).then(res => {
        this.childTypeList = res.result
      })
    },
    handleChange(value) {
      this.queryRestrictChildList(value)
    },
    handleChangeChild(value) {
      
    },
    onSearch() {
      this.pagination.current = 1
      this.queryRestrictList()
    },
    changePage(current) {
      this.pagination.current = current
      this.queryRestrictList()
    },
  },
}
</script>

<style  lang="scss">
.ant-col{
  p{
    font-weight: bolder;
  }
}
</style>
