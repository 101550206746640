<template>
  <div>
    <a-modal v-model="editVisible" title="编辑" @ok="handleOk" class="edit-modal">
       <p>限制类别：<br/><br/>
         <a-select
            size="default"
            placeholder="选择限制类别"
            style="width: 250px"
            :default-value="ADD_PARAMS.restrictType"
            @change="handleAddChange"
          >
            <a-select-option v-for="i in typeList" :key="i.typeConstant">
              {{ i.typeName}}
            </a-select-option>
          </a-select>
        </p>
        <p>限制子类别：<br/><br/>
         <a-select
            size="default"
            placeholder="选择限制子类别"
            style="width: 250px"
            :default-value="ADD_PARAMS.constant == '' ? '' : ADD_PARAMS.constant"
            @change="handleAddChangeChild"
          >
            <a-select-option v-for="i in childTypeList" :key="i.constant">
              {{ i.name}}
            </a-select-option>
          </a-select>
        </p>
        <p>限制名称：<br/><br/> <a-input v-model="ADD_PARAMS.restrictName" style="width: 300px;"/> </p>
        <p>限制描述：<br/><br/> <a-input v-model="ADD_PARAMS.restrictDescribe" style="width: 300px;"/> </p>
        <p>限制条件：<br/><br/>{{ADD_PARAMS.restrictCondition}}<br/><br/>重新设置限制条件：<br/><br/>
          <a-input-group compact >
          <a-select style="width: 60px;" @change="handleAddChangeCondition" :default-value="conditionTag">
            <a-select-option :value="item" v-for="(item) in condition" :key="item">
              {{item}}
            </a-select-option>
          </a-select>
           <a-input-number id="inputNumber" v-model="conditionNumber" style="width: 160px;"/>
        </a-input-group>
        </p>
        <p>异常提示信息：<br/><br/> <a-input v-model="ADD_PARAMS.exceptionName" style="width: 300px;"/> </p>
        <p>配置是否通用：<br/><br/>
            <a-radio-group v-model="ADD_PARAMS.common" @change="onChangeCommon">
              <a-radio :value="true">
                是
              </a-radio>
              <a-radio :value="false">
                否
              </a-radio>
            </a-radio-group>
          </p>
          <p>行政区类别：<br/><br/>
              <a-select style="width: 250px;" @change="handleAddChangeRegionType">
                <a-select-option :value="item" v-for="(item, index) in regionTypes" :key="index">
                  {{item}}
                </a-select-option>
              </a-select>
            </p>
          <p>行政区编号：<br/><br/>
            <a-input v-model="ADD_PARAMS.regionCode" style="width: 250px;"/>
          </p>
    </a-modal>
  </div>
</template>

<script>
import { queryRestrictTypeListApi, queryRestrictChildListApi, updateRestrictApi, queryExceptionRegionTypeApi} from '@/api/restrict'


export default {
  name: "restrictList",
  data() {
    return {
      conditionTag: '',
      conditionNumber: '',
      ADD_PARAMS: {
        restrictType: '',
        restrictName: '',
        restrictDescribe: '',
        constant: '',
        restrictCondition: '',
        exceptionName: '',
        common: '',
        regionCode: ''
      },
      condition: ['>=', '>', '=', '<=', '<', '!='],
      loading: true,
      typeList: [],
      childTypeList: [],
      editVisible: false,
      regionTypes: []
    }
  },
  mounted() {
  },
  methods: {
    queryExceptionRegionType() {
      queryExceptionRegionTypeApi().then(res => {
        this.regionTypes = res.result
      })
    },
    onChangeCommon(e) {
      this.ADD_PARAMS.common = e.target.value
    },
    handleAddChangeRegionType(e) {
      this.ADD_PARAMS.regionType = e
    },
    handleOk() {
        this.ADD_PARAMS.restrictCondition = this.conditionTag + this.conditionNumber
        updateRestrictApi({...this.ADD_PARAMS}).then(res => {
            this.$message.success('修改成功')
            this.$emit('query')
            this.editVisible = false

        })
    },
    showEditModal(data) {
        this.editVisible = true
        this.ADD_PARAMS = {restrictCondition: ''}
        this.conditionTag = ''
        this.conditionNumber = ''
        this.ADD_PARAMS = {...data}
        this.queryRestrictTypeList()
        this.seperateNumber(data)
        this.queryExceptionRegionType()
    },
    seperateNumber(data) {
        let tmp = []
        this.condition.forEach(item => {
            if(data.restrictCondition.indexOf(item) == 0) {
                tmp.push(item)
            }
        })
        let tmpLength = 0
        let tmpCondition = ''
        tmp.forEach(item => {
            if(item.length > tmpLength){
                tmpLength = item.length
                tmpCondition = item
            }
        })
        this.conditionTag = tmpCondition
        this.conditionNumber = this.ADD_PARAMS.restrictCondition.substring(this.conditionTag.length , this.ADD_PARAMS.restrictCondition.length )
    },
    handleAddChange(value) {
      this.ADD_PARAMS.restrictType = value
      this.queryRestrictChildList(value)
    },
    handleAddChangeChild(value) {
      this.ADD_PARAMS.constant = value
    },
    handleAddChangeCondition(value) {
      this.conditionTag = value
    },
    showAddModal() {
      this.addVisible = true
    },
    queryRestrictTypeList() {
      queryRestrictTypeListApi().then(res => {
        this.typeList = res.result
        if(this.ADD_PARAMS.constant) {
            this.queryRestrictChildList(this.ADD_PARAMS.restrictType)
        }
      })
    },
    queryRestrictChildList(data) {
      queryRestrictChildListApi({typeConstant: data}).then(res => {
        this.childTypeList = res.result
      })
    },
  },
}
</script>

<style  lang="scss">
  .ant-modal-body{
    height: 665px;
    overflow: auto;
  }
</style>
